import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'

import ContentfulPage from '../components/Page/ContentfulPage'
import withContentfulBlocks from '../components/withContentfulBlocks'

class Calculator extends React.Component {
  render () {
    return (
      <ContentfulPage
        blocks={this.props.blocks}
        siteName={get(this.props, 'data.site.siteMetadata.siteName')}
        pageTitle={`${get(this.props, 'data.site.siteMetadata.siteName')}`}
        pageUrl={`${get(this.props, 'data.site.siteMetadata.url')}/calculator`}
        footerColor='white'
      />
    )
  }
}

export default withContentfulBlocks(Calculator)

export const pageQuery = graphql`
  query SLCalculatorQuery {
    site {
      ...SiteMetaDataFragment
    }
    page: contentfulPage(contentful_id: { eq: "7J04Vdt28u6pcCbsTlt5Ie" }) {
      ...PageBlocksFragment
    }
  }
`
